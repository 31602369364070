/* global jQuery */

var $ = jQuery,
    App = {
        debug: true,
        wcag_state: {
            init: function() {

                var json = sessionStorage.getItem("wcag_state");

                if (json && json.length) {

                    var data = JSON.parse(json);
                    if (data instanceof Object) {

                        this.data.color = data.color;
                        this.data.text_size = data.text_size;
                        this.data.animation = data.animation;
                        this.data.plain_text = data.plain_text;

                        $("html").addClass(this.data.color + " " + this.data.text_size + " " + this.data.animation + " " + this.data.plain_text);
                    }
                }

            },
            set: function(name, value) {
                this.data[name] = value;
                console.log(this.data);
                this.save();
            },
            reset: function() {
                this.data.color = "";
                this.data.text_size = "";
                this.data.animation = "";
                this.data.plain_text = "";

                this.save();
            },
            save: function() {
                var json = JSON.stringify(this.data);

                sessionStorage.setItem("wcag_state", json);
            },
            data: {
                color: "",
                text_size: "",
                animation: "",
                plain_text: "",
            }
        },
        init: function() {

            this.wcag_state.init();
            this.scripts();
            this.sliders();
            this.mobMenu($(".page-header"));

        },
        loaded: function() {
            $('.home-loader').fadeOut(100);
        },

        scripts: function() {

            if (App.is_touch_device()) {
                $("body").addClass("touch");
            } else {
                $("body").addClass("no-touch");
            }

            var $win = $(window),
                $html = $("html"),
                $body = $("body", $html),
                scrollPos = $win.scrollTop(),
                headerHeight = $('.page-header').outerHeight(),
                $advSearchTrigger = $(".advanced-filters-trigger"),
                $advSearchFields = $(".advanced-filters"),
                $homeLoader = $('.home-loader');

            setTimeout(function() {
                $homeLoader.fadeOut(500);
            }, 5000);



            $win.on('scroll', function() {

                if ($(this).scrollTop() >= 50) {
                    $('body').addClass('page-scrolled');
                } else {
                    $('body').removeClass('page-scrolled');
                }

                scrollPos = $win.scrollTop();

            });

            if ($win.scrollTop() >= 50) {
                $('body').addClass('page-scrolled');
            } else {
                $('body').removeClass('page-scrolled');
            }

            // Advanced search
            $advSearchTrigger.on("click", function() {
                $(this).toggleClass('active');
                $advSearchFields.toggleClass('advanced-filters-opened');
            });

            // To top
            $(".back-top").on("click", function() {
                $("html,body").animate({
                    scrollTop: 0
                }, 550);
            });

            // Content link
            $(".content-link").click(function(e) {
                if ($(this).attr('data-slug').length && (typeof $(this).data('slug') !== 'undefined')) {
                    e.preventDefault();
                    var id = $(this).attr('data-slug');
                    $('html,body').animate({
                        scrollTop: $('#' + id).offset().top
                    }, 550);
                }
            });

            var $homeLatestPosts = $('.latest-posts-block'),
                $blockTrigger = $('.block-trigger', $homeLatestPosts);

            $blockTrigger.click(function(e) {
                $homeLatestPosts.toggleClass('block-closed');
            });

            function floatLabel(inputType) {
                $(inputType).each(function() {
                    var $this = $(this);
                    var text_value = $(this).val();

                    // on focus add class "active" to label
                    $this.focus(function() {
                        $this.parent().prev().addClass("active");
                    });

                    // on blur check field and remove class if needed
                    $this.blur(function() {
                        if ($this.val() === '' || $this.val() === 'blank') {
                            $this.parent().prev().removeClass('active');
                        }
                    });

                    // Check input values on postback and add class "active" if value exists
                    if (text_value != '') {
                        $this.parent().prev().addClass("active");
                    }
                });

                // Automatically remove floatLabel class from select input on load
                $("select").parent().prev().removeClass('active');
            }
            // Add a class of "floatLabel" to the input field
            floatLabel(".gfield input");
            floatLabel(".gfield textarea");

            var $pageHeader = $('.page-header'),
                $searchTrigger = $(".search-trigger"),
                $searchClose = $(".search-close"),
                $wcagBlock = $(".accessibility-options-wrapper"),
                $wcagTrigger = $(".accessibility-trigger"),
                $wcagClose = $(".accessibility-close", $wcagBlock),
                $wcagBlackWhite = $(".white-on-black-btn", $wcagBlock),
                $wcagContrast = $(".black-on-white-btn", $wcagBlock),
                $wcagTextUp = $(".increase-text-btn", $wcagBlock),
                $wcagTextDown = $(".decrease-text-btn", $wcagBlock),
                $wcagNoAnim = $(".no-animations-btn", $wcagBlock),
                $wcagPlainText = $(".plain-text-btn", $wcagBlock),
                $wcagReset = $(".reset-btn", $wcagBlock);

            $searchTrigger.click(function(e) {
                e.preventDefault();
                $pageHeader.addClass("search-opened");
                $(this).parent().addClass("search-opened");
                $(this).parent().find('form input[type=text]').focus();
            });

            $searchClose.click(function(e) {
                e.preventDefault();
                $pageHeader.removeClass("search-opened");
                $(this).parent().parent().removeClass("search-opened");
            });

            $wcagTrigger.click(function(e) {
                e.preventDefault();

                if ($win.width() < 1200) {
                    $pageHeader.removeClass("menu-opened");
                    $body.removeClass("menu-opened");
                }

                $body.css('padding-top', $wcagBlock.outerHeight());
                $pageHeader.addClass("wcag-opened");
                $(this).parent().addClass("wcag-opened");
            });

            $wcagClose.click(function(e) {
                e.preventDefault();
                $body.css('padding-top', 0);
                $pageHeader.removeClass("wcag-opened");
                $(this).parent().parent().removeClass("wcag-opened");
            });

            // WCAG styles

            $wcagBlackWhite.click(function(e) {
                e.preventDefault();

                $html.removeClass('contrast-styles white-on-black-styles')

                if ($wcagBlackWhite.hasClass("active")) {
                    $wcagBlackWhite.removeClass("active");
                    App.wcag_state.set("color", "")
                } else {
                    $wcagContrast.removeClass("active");
                    $wcagBlackWhite.addClass("active");
                    $html.addClass('white-on-black-styles');
                    App.wcag_state.set("color", "white-on-black-styles")
                }
            });

            $wcagContrast.click(function(e) {
                e.preventDefault();

                $html.removeClass('contrast-styles white-on-black-styles')

                if ($wcagContrast.hasClass("active")) {
                    $wcagContrast.removeClass("active");
                    App.wcag_state.set("color", "")
                } else {
                    $wcagBlackWhite.removeClass("active");
                    $wcagContrast.addClass("active");
                    $html.addClass('contrast-styles');
                    App.wcag_state.set("color", "contrast-styles")
                }

            });

            if (App.wcag_state.data.color) {
                if (App.wcag_state.data.color == "white-on-black-styles") {
                    $wcagBlackWhite.addClass("active");
                }
                if (App.wcag_state.data.color == "contrast-styles") {
                    $wcagContrast.addClass("active");
                }
            }

            $wcagTextUp.click(function(e) {
                e.preventDefault();

                if ($html.hasClass('text-size-xxl-styles')) {
                    return;
                } else if ($html.hasClass('text-size-xs-styles')) {
                    $html.removeClass('text-size-xs-styles');
                    $html.addClass('text-size-s-styles');

                    App.wcag_state.set("text_size", "text-size-s-styles")

                    setTimeout(function() {
                        $body.css('padding-top', $wcagBlock.outerHeight());
                    }, 400);
                } else if ($html.hasClass('text-size-s-styles')) {
                    $html.removeClass('text-size-s-styles');
                    $html.addClass('text-size-m-styles');

                    App.wcag_state.set("text_size", "text-size-m-styles")

                    setTimeout(function() {
                        $body.css('padding-top', $wcagBlock.outerHeight());
                    }, 400);
                } else if ($html.hasClass('text-size-l-styles')) {
                    $html.removeClass('text-size-l-styles');
                    $html.addClass('text-size-xl-styles');

                    App.wcag_state.set("text_size", "text-size-xl-styles")

                    setTimeout(function() {
                        $body.css('padding-top', $wcagBlock.outerHeight());
                    }, 400);
                } else if ($html.hasClass('text-size-xl-styles')) {
                    $html.removeClass('text-size-xl-styles');
                    $html.addClass('text-size-xxl-styles');

                    App.wcag_state.set("text_size", "text-size-xxl-styles")

                    setTimeout(function() {
                        $body.css('padding-top', $wcagBlock.outerHeight());
                    }, 400);
                } else {
                    $html.removeClass('text-size-m-styles');
                    $html.addClass('text-size-l-styles');

                    App.wcag_state.set("text_size", "text-size-l-styles")

                    setTimeout(function() {
                        $body.css('padding-top', $wcagBlock.outerHeight());
                    }, 400);
                }

            });

            $wcagTextDown.click(function(e) {
                e.preventDefault();

                if ($html.hasClass('text-size-xs-styles')) {
                    return;
                } else if ($html.hasClass('text-size-xxl-styles')) {
                    $html.removeClass('text-size-xxl-styles');
                    $html.addClass('text-size-xl-styles');

                    App.wcag_state.set("text_size", "text-size-xl-styles")

                    setTimeout(function() {
                        $body.css('padding-top', $wcagBlock.outerHeight());
                    }, 400);
                } else if ($html.hasClass('text-size-xl-styles')) {
                    $html.removeClass('text-size-xl-styles');
                    $html.addClass('text-size-l-styles');

                    App.wcag_state.set("text_size", "text-size-l-styles")

                    setTimeout(function() {
                        $body.css('padding-top', $wcagBlock.outerHeight());
                    }, 400);
                } else if ($html.hasClass('text-size-l-styles')) {
                    $html.removeClass('text-size-l-styles');
                    $html.addClass('text-size-m-styles');

                    App.wcag_state.set("text_size", "text-size-m-styles")

                    setTimeout(function() {
                        $body.css('padding-top', $wcagBlock.outerHeight());
                    }, 400);
                } else if ($html.hasClass('text-size-s-styles')) {
                    $html.removeClass('text-size-s-styles');
                    $html.addClass('text-size-xs-styles');

                    App.wcag_state.set("text_size", "text-size-xs-styles")

                    setTimeout(function() {
                        $body.css('padding-top', $wcagBlock.outerHeight());
                    }, 400);
                } else {
                    $html.removeClass('text-size-m-styles');
                    $html.addClass('text-size-s-styles');

                    App.wcag_state.set("text_size", "text-size-s-styles")

                    setTimeout(function() {
                        $body.css('padding-top', $wcagBlock.outerHeight());
                    }, 400);
                }

            });

            $wcagNoAnim.click(function(e) {
                e.preventDefault();
                $(this).toggleClass('active');

                if ($html.hasClass('no-anim-styles')) {
                    $html.removeClass('no-anim-styles');
                    App.wcag_state.set("animation", "");
                } else {
                    $html.addClass('no-anim-styles');
                    App.wcag_state.set("animation", "no-anim-styles");
                }
            });
            if (App.wcag_state.data.animation) {
                $wcagNoAnim.addClass("active");
            }

            $wcagPlainText.click(function(e) {
                e.preventDefault();
                $(this).toggleClass('active');

                if ($html.hasClass('plain-text-styles')) {
                    $html.removeClass('plain-text-styles');
                    App.wcag_state.set("plain_text", "");
                } else {
                    $html.addClass('plain-text-styles');
                    App.wcag_state.set("plain_text", "plain-text-styles");
                }

                setTimeout(function() {
                    $body.css('padding-top', $wcagBlock.outerHeight());
                }, 400);
            });
            if (App.wcag_state.data.plain_text) {
                $wcagPlainText.addClass("active");
            }

            $wcagReset.click(function(e) {
                e.preventDefault();
                $('html, body').removeClass('white-on-black-styles contrast-styles no-anim-styles plain-text-styles text-size-xs-styles text-size-s-styles text-size-l-styles text-size-xl-styles text-size-xxl-styles');
                $('.btn', $wcagBlock).removeClass('active');

                App.wcag_state.reset();
                setTimeout(function() {
                    $body.css('padding-top', $wcagBlock.outerHeight());
                }, 400);
            });

        },

        sliders: function() {

            var $win = $(window);

            var slides_bg_slider = $('.bg-slider.swiper-container');

            var slides_bullets_slider = $('.bullets-slider.swiper-container');

            if (slides_bg_slider.length && slides_bullets_slider.length) {

                if ($win.width() > 767) {

                    var slides_bullets_swiper_slider, slides_bg_swiper_slider,
                        slides_ended = false;

                    if (!slides_bg_swiper_slider) {

                        $('.swiper-slide', slides_bullets_slider).first().addClass('is-selected');

                        slides_bullets_swiper_slider = new Swiper(slides_bullets_slider, {
                            slidesPerView: 4,
                            slidesPerColumn: 2,
                            slidesPerColumnFill: 'row',
                            preventInteractionOnTransition: true,
                            slideToClickedSlide: true,
                            simulateTouch: true,
                            watchSlidesVisibility: true,
                            watchSlidesProgress: true,
                            on: {
                                click: function() {

                                    var clicked = slides_bullets_swiper_slider.clickedIndex;
                                    // console.log(clicked);

                                    slides_bullets_swiper_slider.activeIndex = clicked; //don't need this
                                    slides_bullets_swiper_slider.updateSlidesClasses(); //don't need this
                                    $(slides_bullets_swiper_slider.slides).removeClass('is-selected');
                                    $(slides_bullets_swiper_slider.clickedSlide).addClass('is-selected');
                                    slides_bg_swiper_slider.slideTo(clicked, 500, false);
                                }
                            }
                        });

                        slides_bg_swiper_slider = new Swiper(slides_bg_slider, {
                            slidesPerView: 1,
                            spaceBetween: 0,
                            effect: 'fade',
                            preventInteractionOnTransition: true,
                            watchSlidesVisibility: true,
                            watchSlidesProgress: true,
                            keyboard: {
                                enabled: false,
                                onlyInViewport: false,
                            },
                            speed: 1200,
                            autoplay: {
                                delay: 4000,
                            },
                            // autoplay: false,
                            thumbs: {
                                //swiper: slides_bullets_swiper_slider,
                            },
                            on: {
                                slideChangeTransitionStart: function() {

                                    var activeIndex = slides_bg_swiper_slider.activeIndex;

                                    $(slides_bullets_swiper_slider.slides).removeClass('is-selected');
                                    $(slides_bullets_swiper_slider.slides).eq(activeIndex).addClass('is-selected');
                                    slides_bullets_swiper_slider.slideTo(activeIndex, 500, false);
                                }
                            }
                        });

                    } else {

                        //slides_bg_swiper_slider.destroy(true, true);

                    }


                }
            }

        },

        mobMenu: function(menuWrapper) {

            var $this = menuWrapper,
                $body = $("body");

            /* toggle main menu nav */
            $(".menu-btn", $this).on("click", function() {

                menuWrapper.toggleClass("menu-opened");
                $body.toggleClass("menu-opened");

            });

            $(window).on("keyup", function(event) {

                if ($body.hasClass("menu-opened")) {
                    switch (event.keyCode) {
                        case 27: //esc
                            menuWrapper.removeClass("menu-opened");
                            $body.removeClass("menu-opened");
                            break;
                    }
                }

            });

        },

        log: function(msg) {
            if (this.debug) {
                if (arguments.length > 1) {
                    var to_console = [];
                    for (i = 1; i < arguments.length; i++) {
                        to_console[i - 1] = arguments[i][0];
                    }
                    console.log(msg, to_console);
                } else {
                    console.log(msg);
                }
            }
        },
        is_touch_device: function() {
            return (("ontouchstart" in window) || (navigator.MaxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
        },

    };

jQuery(document).ready(function() {
    App.init();
});
jQuery(window).on("load", function() {
    App.loaded();
});